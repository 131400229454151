<template>
  <!-- hero section -->
  <div class="hero">
      <div class="contact">
          <form onSubmit="SubmitMessage(); return false">
            <h1 class="center">Drop a message</h1>
            <input type="text" placeholder="Full Name" id="fullName" />
            <input type="email" placeholder="Email" id="email" />
            <textarea
              name="message"
              placeholder="Message"
              cols="30"
              rows="4"
              id="message"
            ></textarea>
            <input type="submit" value="Send Message" />
          </form>
        </div>
  </div>
</template>

<style scoped>
/* start of hero */
.hero {
  background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 97.92%),
    url("https://res.cloudinary.com/hayche-network/image/upload/v1646928338/website/images/contact/hero_voivk8.png");
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: grid;
  place-items: center;
}

.contact {
  width: 50%;
  background-color: rgba(255, 255, 255, 0.884);
  padding: 30px;
  border-radius: var(--button-border-radius);
}

@media (max-width: 768px) {
  .contact {
    width: 100%;
    margin: 0 auto;
  }
}

.contact h1 {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 12px;
  align-items: left;
  color: #000000;
}

.contact p {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #000000;
}



/* end of hero */
</style>