<template>
  <hero />
</template>

<script>
import hero from "./hero";

export default {
  components: {
    hero,
  },
};
</script>

